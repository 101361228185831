
@import url('https://fonts.googleapis.com');
@import url('https://fonts.gstatic.com');
@import url('https://fonts.googleapis.com/css2?family=Corinthia:wght@400;700&family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    position: relative;
    margin: 0;
    font-family: Helvetica Neue, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(34, 31, 32);
    display: grid;
    width: 100vw;
    height: 100vh;
    user-select: none;
}

html,
a {
    color: #eee7d7;
    color: rgba(238, 231, 215, 1);
}

#root {
    display: flex;
    align-items: center;
    background: url('../public/images/1449998670.png') no-repeat center center;
    background-size: cover;
    color: #eee7d7;
    /* color: rgba(238, 231, 215, 1); */
}

.dashboard-container {
    max-width: 1002px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.my-3 {
    font-family: "Corinthia", serif;
    align-self: flex-start;
    font-size: 3rem;
    line-height: 30px;
    margin-top: 2rem;

    @media screen and (max-width: 360px) {
        margin-top: 0;
    }
}

.my-3-tagline {
    text-align: right;
    margin-bottom: 1.5rem;
}

.my-2 {
    margin: 0 0.5rem;
    border-radius: 0.5rem;
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 2rem;
    height: 230px;
    overflow: scroll;
    transition: all 0.5s ease;

    &.active {
        opacity: 1;
    }

    @media screen and (max-width: 600px) {
        height: 270px;
    }

    @media screen and (max-width: 360px) {
        display: none;
    }
}

.certificate-ref {
    font-size: 16px;
}

.certificate-image {
    height: 120px;
    margin: 10px;
    filter: sepia(20);
}

.social-icons {
    max-width: 350px;
    width: 100%;
    display: none;
    @media screen and (max-width: 360px) {
        display: block;
    }
}
.resume-item {
    font-size: 16px;
    line-height: 22px;

    @media screen and (max-width: 400px) {
        font-size: 16px;
        line-height: 22px;
    }
}

.resume-item-edu {
    font-size: 16px;
    line-height: 20px;
}

.resume-item-link {
    margin-left: 0.3rem;
    white-space: nowrap;
}

.main-menu {
    cursor: pointer;
}

nav {
    max-width: 960px;
    mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 15%, #ffffff 85%, rgba(255, 255, 255, 0) 100%);
    margin: 0 auto;

    @media screen and (max-width: 360px) {
        display: none;
    }
}

nav ul {
    text-align: center;
    background: linear-gradient(90deg, rgba(239, 229, 185, 0) 0%, rgba(239, 229, 185, 0.2) 25%, rgba(239, 229, 185, 0.2) 75%, rgba(239, 229, 185, 0) 100%);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
    padding-inline-start: 40px;
    padding-inline-end: 40px;
    margin: 0 0 0.5rem 0;

    @media screen and (max-width: 600px) {
        padding-inline-start: 15px;
        padding-inline-end: 15px;
    }
}

nav ul li {
    display: inline-block;
}

nav ul li .main-menu {
    padding: 12px 22px;
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    display: block;

    &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.1);
        color: #eee7d7;
    }

    &.active {
        color: #eee7d7;
    }

    @media screen and (max-width: 470px) {
        padding: 12px;
    }

    @media screen and (max-width: 380px) {
        padding: 12px 9px;
    }
}

.resume-icons {
    max-width: 350px;
    width: 100%;
}

.footer-icon-lg {
    color: rgba(237, 231, 217, 0.3);
    margin-left: 1rem;
    margin-right: 1rem;
}

.footer-icon-lg>svg {
    width: 26px;
    height: 26px;
}

.footer-icon-lg>svg:hover {
    color: rgba(238, 231, 215, 0.7);
    transform: scale(1.2);
}


.resume-header {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 10px;
    text-transform: uppercase;
    font-weight: 900;
}

.skill-items {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.skill-item {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 30px;
    display: inline;
}

.skill-logos {
    padding: 0 0.5rem;
}

.skill-logo {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    filter: sepia(100%);

    &:hover {
        transform: scale(1.1);
    }
}

.skill-logo img {
    max-width: 100%;
    border-radius: 5px;
}

.skill-logos {
    display: flex;
    gap: 8px;
    justify-content: center;
}

.buy-me-a-coffee img {
    margin-top: 10px;
}